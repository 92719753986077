.contact {
	.title {
		margin-bottom: 8vw;
		h1 {
			font-family: "CondorCompressed", sans-serif;
			font-size: 8.4vw;
			line-height: 0.9em;
			font-weight: 400;
			color: var(--color-titles);

			@media #{$medium} {
				font-size: 12.6vw;
			}
		}
	}

	.description {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-family: "Roboto", sans-serif;

		.wrap {
			flex: 0 40%;
			transform: translateX(-10vw);

			@media #{$medium} {
				flex: 0 50%;
				transform: translateX(0vw);
			}

			@media #{$small} {
				flex: 1;
			}

			a {
				margin-bottom: 8vw;
				font-size: 1.25vw;
				letter-spacing: 1px;
				display: inline-block;
				line-height: 1.7em;
				color: var(--color-paragraphs);

				@media #{$medium} {
					font-size: 2.25vw;
				}

				@media #{$small} {
					font-size: 2.7vw;
				}
			}
			p {
				font-size: 1vw;
				line-height: 1.7em;
				color: var(--color-paragraphs);

				@media #{$medium} {
					font-size: 2vw;
				}

				@media #{$small} {
					font-size: 2.7vw;
				}
			}
		}
	}
}
