*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

:root {
	--root-color-bg: $color-black;
    --root-html-font-size: 16px;
    --root-html-line-height: normal;
}

html {
    font-size: var(--root-html-font-size) !important;
    line-height: var(--root-html-line-height) !important;
}

html,
body {
	font-family: $font-condensed;
	font-size: 100%;
	overflow: hidden;
	background-color: var(--root-color-bg);
	transition: $transition-snap background-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
	margin-bottom: 0;
}

a {
	text-decoration: none;
}

#app {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 1;
}

#scene {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

#scene-project {
    position: relative;
    width: 100%;
    height: 50vw;
    z-index: 1;
    margin-left: -4vw;
    margin-bottom: 8vw;
}

.cursor {
	--size: 100px;
	width: var(--size);
	height: var(--size);
	position: fixed;
	margin-left: calc(var(--size) / -2);
	margin-top: calc(var(--size) / -2);
	border: 1px solid var(--color-cursor);
	border-radius: 50%;
	will-change: transform, opacity, border;
	transition: 200ms ease-out transform, 300ms ease-in opacity, 300ms ease-in border;
	pointer-events: none;
	transform: scale(0);
	z-index: 999999;
	opacity: 1;
	top: 0;
	left: 0;
}

.sidebar {
	grid-area: nav;
	justify-self: start;
	display: grid;
	padding: 4vw;
	grid-auto-columns: 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	transform: $transform-moveUpSkew;
	will-change: transform;
	width: 100%;
    height: 100vh;

	@media #{$medium} {
		grid-template-rows: 1fr;
		grid-template-columns: 1fr 1fr 1fr;
        height: unset;
	}

	.circleLink {
		width: 80px;
		height: 80px;
		justify-self: center;
		align-self: start;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 3;
		padding: 10px;
		transition: 100ms ease-out transform;

		@media #{$medium} {
			justify-self: start;
			align-self: center;
			width: 64px;
			height: 64px;
		}

		&:hover > .circleWrap .line {
			transform: rotate(-45deg) !important;
		}

		&:hover > .circleWrap .reverse {
			transform: rotate(45deg) !important;
		}

		.circleBorder {
			position: absolute;
			border: 1px solid var(--color-paragraphs);
			border-radius: 50%;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			opacity: 0.4;
			z-index: 0;
			padding: 30px;
		}

		.circleWrap {
			display: flex;
			flex-direction: row;
			transition: 100ms ease-out transform;

			.circle {
				width: 4px;
				height: 4px;
				background-color: var(--color-paragraphs);
				border-radius: 50%;
				margin: 2px;
			}

			.line {
				width: 26px;
				height: 1px;
				background-color: var(--color-paragraphs);
				transform: rotate(45deg);
				margin-right: -13px;
				transition: $transition-snap transform;

				&.reverse {
					transform: rotate(-45deg);
					margin-right: 0px;
					margin-left: -13px;
				}
			}
		}
	}

	h1 {
		justify-self: center;
		align-self: center;
		font-size: 16px;
		text-transform: uppercase;
		transform: rotate(180deg);
		writing-mode: vertical-rl;
		color: var(--color-paragraphs);
		letter-spacing: 0.04em;

		@media #{$medium} {
			writing-mode: unset;
			transform: rotate(0deg);
		}
	}

	h2 {
		justify-self: center;
		align-self: end;
		font-size: 16px;
		text-transform: uppercase;
		transform: rotate(180deg);
		writing-mode: vertical-rl;
		color: var(--color-subtitles);
		letter-spacing: 0.04em;

		@media #{$medium} {
			justify-self: end;
			align-self: center;
			writing-mode: unset;
			transform: rotate(0deg);
		}
	}
}

@mixin icon-link {
	background-image: url("../assets/icon-link.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.8rem;

    img {
        width: 50%;
        margin-bottom: 2rem;
    }
}
